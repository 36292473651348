@import url('https://rsms.me/inter/inter.css');
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}



.trails-main {
  position: relative;
  width: 70%;
  height: 100%;
  overflow: hidden;
  display: flex;
  padding-left: 5%;
  justify-content: start;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .trails-text {
    font-size: clamp(1rem,3rem,1vw);
    line-height: 10px;
    background-color: blueviolet;

   }
  }
.trails-text {
  
  position: relative;
  width: 100%;
  height: 110px;
  line-height: 110px;
  color: #FED44B;
  font-size: clamp(3rem,7rem,6vw);
  font-weight: 800;
  letter-spacing: -3px;
  will-change: transform, opacity;
  overflow: hidden;
}

.trails-text > div {
  overflow: hidden;
}

.transitions-item {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 5em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 80px;
}
