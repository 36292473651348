*{
    margin:  0;
    padding : 0;
}
html{
    scroll-behavior: smooth;
    
}
body{
    font-family: sans-serif !important;
    

}